import {
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBNavbarLink
} from 'mdb-react-ui-kit';

import Card from '../components/Card';
import Footer from '../components/Footer';
import React from 'react';

function Home(){
   
    return(
    
        <>
            
            <section>
                <Card title="Landing Page" 
                text="Acceda al enlace de la página oficial o correo electrónico">
                </Card>
                <section>
                <MDBCard background='primary' className='text-white mb-3' alignment='center'>
      
                    <MDBCardBody>
                        <MDBCardTitle>Índice de Contenidos</MDBCardTitle>
                        <MDBCardText className='text-white mb-3'>
                        Seleccione su opción de navegación
                        </MDBCardText>
                        <br/>
                        <MDBNavbarLink className='link-dark' href='https://escuelaigc.cl:2096/'>Acceso correos</MDBNavbarLink>
                        <br/>
                        <MDBNavbarLink className='link-warning' href='https://www.isabelgonzalezcares.cl/'>Página oficial</MDBNavbarLink>
                        <br/>
                       
                        
                    </MDBCardBody>
                </MDBCard>
                </section>
            
        </section>
        <Footer/>
        </>
    
    )

}

export default Home;