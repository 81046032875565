import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Route,
  Routes,
}from 'react-router-dom';

import Home from './pages/Home';
import Missing from './pages/Missing';

function App() {
  
  return (

      
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/*" element={<Missing/>}/>
          
              
        </Routes> 
    
  
  );
}

export default App;
