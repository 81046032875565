import {
  MDBContainer,
  MDBFooter
} from 'mdb-react-ui-kit';

import React from 'react';

export default function App() {
  return (
    <MDBFooter className='text-center text-white mt-5' style={{ backgroundColor: '#0a0a0a' }} >
      <MDBContainer className='p-4 pb-0'>
        <section className=''>
          <p className='d-flex justify-content-center align-items-center'>
            <span className='me-3'>Cont&aacute;ctanos:</span>
            <a href="mailto:contacto@escuelaigc.cl?subject=contacto a través de la web">
              contacto@escuelaigc.cl
            </a>
          </p>
        </section>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2023 Copyright: Corporación Isabel Gonzalez Cares
      </div>
    </MDBFooter>
  );
}