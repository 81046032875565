import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
} from 'mdb-react-ui-kit';

import React from 'react';

export default function Card({title,text,children}) {
  return (
    <div className="d-flex justify-content-center">
    
        <MDBCard 
        style={{width:'80%'}} 
        alignment='center' 
        background='warning' 
        className='mb-4 text-primary bg-opacity-75 mt-2'
        >
        <MDBCardBody>
            <MDBCardTitle>{title}</MDBCardTitle>
            <MDBCardText>
            {text}
            </MDBCardText>
        </MDBCardBody>
        </MDBCard>
    </div>

    
  );
}


/*import React from 'react';

function Card({title,children}){
    return(
        <section id="card">
        <header>
            <h3>{title}</h3>
        </header>
       {children}
    </section>
    )

}

export default Card; */